<div id="headercontainer">
  <div>
    <a target="_blank" href="https://karpinskyinstitute.ru/ru/" rel="noopener">
      <img src="./assets/images/logokarpinsky.png" width="50" height="50" />
    </a>
    <div>
      <a target="_blank" href="https://karpinskyinstitute.ru/ru/" rel="noopener" class="txtblue">
        ИНСТИТУТ<br />КАРПИНСКОГО
      </a>
    </div>
    <a [routerLink]="'/'" id="title" class="txtblue" style="margin: auto;">
      Геолого-картографическая платформа визуализации и анализа химико-аналитических определений для интерпретации
      петрохимических данных
    </a>

  </div>
  <div>
    <a routerLink="" style="min-width: 110px;">
      <svg-icon src="combo-chart" [stretch]="true"
        [svgStyle]="{'width.px':24,'padding.px':1,'margin.px':-5,'fill':'#007060'}">
      </svg-icon>
      <span class="txtblue" style="margin-left: 10px;">Приложение</span>
    </a>
    <a routerLink="/database" style="min-width: 110px;">
      <svg-icon src="database" [stretch]="true"
        [svgStyle]="{'width.px':20,'padding.px':1,'margin.px':-4,'fill':'#007060'}">
      </svg-icon>
      <span class="txtblue" style="margin-left: 10px;">База данных</span>
    </a>
  </div>
  <div>

    <button mat-button *ngIf="userDetails!=null" class="txtblue">
      <mat-icon>account_circle</mat-icon>
      {{userDetails.firstName}}
    </button>

    <button *ngIf="userDetails!=null" mat-button class="txtblue" (click)="doLogout()" aria-label="Выйти" title="Выйти">
      <mat-icon>login</mat-icon>
      Выйти
    </button>
    <button *ngIf="userDetails==null" mat-button class="txtblue" (click)="doLogin()" aria-label="Войти" title="Войти">
      <mat-icon>login</mat-icon>
      Авторизоваться
    </button>
    <a title="http://www.mnr.gov.ru/" alt="http://www.mnr.gov.ru/" target="_blank" rel="noopener"
      href="http://www.mnr.gov.ru/">
      <img src="./assets/images/logo_minprir.png" width="40" height="40" />
    </a>&nbsp;&nbsp;&nbsp;
    <a title="http://www.rosnedra.gov.ru/" alt="http://www.rosnedra.gov.ru/" target="_blank" rel="noopener"
      href="http://www.rosnedra.gov.ru/">
      <img src="./assets/images/logo_rosnedra.png" width="40" height="40" />
    </a>
  </div>
</div>