import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';

import { SettingsComponent } from './modalcomponents/settings/settings.component';
import { MessagebarComponent } from './modalcomponents/messagebar/messagebar.component';
import { MessagebarService } from './_services/messagebar.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ConfigService } from './_services/config.service';
import { DataService } from './_services/data.service';
import { LocaldataService } from './_services/localdata.service';
import { KeycloakService, KeycloakAngularModule, KeycloakConfig } from 'keycloak-angular';
import { APP_BASE_HREF } from '@angular/common';
import { AlertComponent } from './modalcomponents/alert/alert.component';
import { RouterComponent } from './router/router.component';
import { MatModule } from './mat.module';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

export function app_Init(appService: ConfigService) {
  return () => appService.getConfigs().then(res => {
    console.log(res);
    return;
  });
}

// Потом исправить
const keycloakConfig: KeycloakConfig = {
  //url: 'http://weblogin.vsegei.ru/auth/realms/master',
  url: 'https://weblogin.vsegei.ru/auth',
  realm: 'master',
  clientId: 'web_diagram',
  credentials: {
    secret: "23ff7830-f503-4e29-a0fa-a43bba766d76"
  }

};

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      // const { keycloakConfig } = environment;
      try {
        await keycloak.init({
          config: keycloakConfig,
          initOptions: {
            onLoad: 'check-sso',//'login-required',
            checkLoginIframe: false,
            flow: 'implicit',
          },
          enableBearerInterceptor: false,
          bearerExcludedUrls: []
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./app-main.module').then(m => m.AppMainModule)
  },
  {
    path: 'database', loadChildren: () => import('./dbeditor/dbeditor.module').then(m => m.DbeditorModule)
  }
];

@NgModule({
  declarations: [
    HeaderComponent, //AppComponent, DatatableComponent, MapComponent, TernaryComponent, DiscriminantComponent, DbeditorComponent,
    SettingsComponent, MessagebarComponent, //FromcapitalPipe, TruncnumPipe, FirstnamePipe, 
    AlertComponent, RouterComponent],
  imports: [
    BrowserModule, BrowserAnimationsModule, AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    // ResizableModule, 
    FormsModule,
    MatModule,
    //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  entryComponents: [
    MessagebarComponent,
    SettingsComponent,
    AlertComponent
  ],
  providers: [
    ConfigService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [ConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [KeycloakService], multi: true, },
    DataService,
    LocaldataService,
    MessagebarService
  ],
  bootstrap: [RouterComponent]
})
export class AppModule { }
