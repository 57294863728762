<h2 mat-dialog-title>{{message.title}}</h2>
<!-- <br /> -->
<div mat-dialog-content>
  <ul>
    <li *ngFor="let mes of message.values">
      {{mes}}
    </li>
  </ul>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Закрыть</button>
</div>