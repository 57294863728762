import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagebarComponent } from '../modalcomponents/messagebar/messagebar.component';

@Injectable({
  providedIn: 'root'
})
export class MessagebarService {

  constructor(private snackBar: MatSnackBar) { }

  doit(message:string) {
    this.snackBar.openFromComponent(MessagebarComponent, {
      duration: 5000,
      data: { message: message },
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }
}
