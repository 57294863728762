import { Injectable } from '@angular/core';
import { Silicate } from '../_model/Silicate';
import { MessagebarService } from './messagebar.service';
import { Settings } from '../modalcomponents/settings/settings.component';

@Injectable({
  providedIn: 'root'
})
export class LocaldataService {

  items: Array<Silicate>;

  settings: Settings = {
    display_discriminant: true,
    display_ternary: true,
    display_text: 'order',
  };

  constructor(
    private showMessage: MessagebarService) {
    this.items = this.GetStorage();
  }

  GetSettings() {
    if (localStorage.getItem('settings'))
      this.settings = JSON.parse(localStorage.getItem('settings'));
    else
      localStorage.setItem('settings', JSON.stringify(this.settings));
    return this.settings;
  }
  SetSettings(settings: Settings) {
    this.settings = settings;
    localStorage.setItem('settings', JSON.stringify(settings));
  }
  GetDisplayText() {
    return this.settings.display_text;
  }

  GetStorage(): Array<Silicate> {
    let storage = new Array<Silicate>();
    if (localStorage.getItem('silicates')) {
      storage = JSON.parse(localStorage.getItem('silicates')) as Silicate[];
    } else {
      localStorage.setItem('silicates', JSON.stringify(storage));
    }
    this.items=storage;
    return storage;
  }
  SetStorage(values: Array<Silicate>): void {
    this.items = values;
    localStorage.setItem('silicates', JSON.stringify(values));
  }
  GetPreparedData(child: 'datatable' | 'discriminant' | 'ternary' | 'map') {
    const displayedItems = this.items.filter(i => i._display);
    if (this.items.length > 0 && displayedItems.length == 0) {
      this.showMessage.doit("Все пробы имеют настройку \"не отображать на графиках\". Включите отображение, чтобы изменить.");
    }
    switch (child) {
      case 'datatable': {
        return this.items;
      }
      case 'ternary': {
        if (displayedItems.length == 0)
          return [];
        else
          return displayedItems.map(x =>
            ({
              id: x.id,
              sampleNum: x.sampleNum,
              X: x.Na2OplusK2O_molar,
              Y: x.Fe2O3_molar_5,
              Z: x.CaOplusMgO_molar_5
            }));

      }
      case 'discriminant': {
        if (displayedItems.length == 0)
          return [];
        else
          return displayedItems.map(x =>
            ({
              id: x.id,
              sampleNum: x.sampleNum,
              X: x._feO / x._mgO,// x['FeO'] / x['MgO'],
              Y: x.zr + x.nb + x.ce + x.y,// x['Zr+Nb+Ce+Y'],
            }));
      }
      case 'map': {
        if (displayedItems.length == 0)
          return [];
        else
          return displayedItems.map(x =>
            ({
              id: x.id,
              sampleNum: x.sampleNum,
              X: x.lon,
              Y: x.lat,
            }));
      }
      default: break;
    }

  }
}
