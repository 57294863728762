import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
    private urlConfig = "./assets/config.json";
  
    constructor(private http: HttpClient) { }
    getConfigs(): Promise<Config> {
      localStorage.removeItem('silicates');
      return this.http.get(this.urlConfig)
        .toPromise()
        .then(res => {
          Config.ggk_url = res['ggk_url'];
          Config.nomenclature_url = res['nomenclature_url'];
          Config.service_url = res['service_url'];
          Config.raster_url = res['raster_url'];
          Config.raster_layers_wms_service_url = res['raster_layers_wms_service_url'];
  
          Config.values = Config.service_url + "/api/values/";
          Config.silicates = Config.service_url + "/api/silicates/";
          Config.silicatesExtra = Config.service_url + "/api/silicatesExtra/";
          Config.users = Config.service_url + "/api/users/";
          return 'ok';
        });
  
    }
  }
  
  export class Config {
    static ggk_url: string = '';
    static nomenclature_url: string = '';
    static service_url: string = '';
    static raster_url: string = '';
    static raster_layers_wms_service_url: string = '';
    // Data from web api
    static values: string = '';
    static silicates: string = '';
    static silicatesExtra: string = '';
    static users: string = '';
  }