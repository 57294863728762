import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userDetails: any;
  path:string;

  constructor(
    private keycloak: KeycloakService,
    private iconReg: SvgIconRegistryService,
    private activateRoute: ActivatedRoute
  ) {    
    this.iconReg.loadSvg('../assets/images/database.svg', 'database').subscribe();
    this.iconReg.loadSvg('../assets/images/combo-chart.svg', 'combo-chart').subscribe();
    // let path = this.activateRoute.pathFromRoot;
    // console.log(path);
   }

  async ngOnInit() {
    if (await this.keycloak.isLoggedIn()) {
      this.userDetails = await this.keycloak.loadUserProfile();
    }
  }
  async doLogin() {
    await this.keycloak.login();
  }
  async doLogout() {
    await this.keycloak.logout();
    this.userDetails = null;
  }

}
