import { Message } from '../modalcomponents/alert/alert.component';

export class Silicate {
    id: string;
    userId: string;
    user: User;
    sampleNum: string;
    siO2: number;
    tiO2: number;
    al2O3: number;
    fe2O3: number;
    feO: number;
    mnO: number;
    mgO: number;
    caO: number;
    na2O: number;
    k2O: number;
    p2O5: number;
    h2O: number;
    ppp: number;
    zr: number;
    nb: number;
    ce: number;
    y: number;
    lat: number;
    lon: number;
    public static readonly Props: string[] = ['sampleNum', 'siO2', 'tiO2', 'al2O3', 'fe2O3', 'feO', 'mnO', 'mgO', 'caO', 'na2O', 'k2O', 'p2O5', 'h2O', 'ppp', 'zr', 'nb', 'ce', 'y', 'lat', 'lon'];
    public static readonly Hidens: string[] = ['id', 'userId'];
    oxides_sum: number;
    elems_sum: number;
    _siO2: number;
    _tiO2: number;
    _al2O3: number;
    _fe2O3: number;
    _feO: number;
    _mnO: number;
    _mgO: number;
    _caO: number;
    _na2O: number;
    _k2O: number;
    _p2O5: number;
    _local_storage: boolean = true;
    _display: boolean = true;
    public static readonly Extras: any[] = [
        { key: 'oxides_sum', value: 'Сумма' },
        { key: 'elems_sum', value: 'Zr+Nb+Ce+Y (350)' },
        { key: 'Fe2O3_total', value: 'Fe2O3 (Total)' },
        { key: 'Fe2O3_molar_5', value: 'Fe2O3 (мол)*5' },
        { key: 'CaOplusMgO_molar_5', value: '(CaO+MgO) (мол)*5' },
        { key: 'Na2OplusK2O_molar', value: 'Na2O+K2O (мол)' },
        { key: 'FeOdivMgO_molar', value: 'FeO*/MgO' }
    ];
    Fe2O3_total: number;
    Fe2O3_molar_5: number;
    CaOplusMgO_molar_5: number;
    Na2OplusK2O_molar: number;
    FeOdivMgO_molar: number;

    public static Calculate(item: Silicate): void {
        let oxides = ['siO2', 'tiO2', 'al2O3', 'fe2O3', 'feO', 'mnO', 'mgO', 'caO', 'na2O', 'k2O', 'p2O5'];
        item.oxides_sum = 0;
        oxides.forEach(oxide => item.oxides_sum += item[oxide]);
        const divider = item.oxides_sum;
        item.oxides_sum += item.h2O;
        item.oxides_sum += item.ppp;
        item.oxides_sum = Math.round(item.oxides_sum * 100) / 100;
        oxides.forEach(oxide => item['_' + oxide] = Math.round(item[oxide] * 10000 / divider) / 100);
        item.elems_sum = 0;
        ['zr', 'nb', 'ce', 'y'].forEach(elem => item.elems_sum += item[elem]);
        item.elems_sum = Math.round(item.elems_sum * 100) / 100;

        item.Fe2O3_total = Math.round((item._fe2O3 + item._feO * 1.111) * 100) / 100;
        item.Fe2O3_molar_5 = Math.round((5 * item.Fe2O3_total * 1000 / (159.68)) * 100) / 100;
        item.CaOplusMgO_molar_5 = Math.round((((item._caO * 1000 / 56.08) + (item._mgO * 1000 / 40.32)) * 5) * 100) / 100;
        item.Na2OplusK2O_molar = Math.round((item._na2O * 1000 / (61.994) + item._k2O * 1000 / 94.2) * 100) / 100;
        item.FeOdivMgO_molar = Math.round(((item._feO + item._fe2O3 * 0.889) / item._mgO) * 100) / 100;
    }
    public static Validate(item: Silicate): Message {
        Silicate.Calculate(item);
        let result = new Array<string>();
        if (item.oxides_sum > 102 || item.oxides_sum < 98) {
            result.push("Сумма равна " + item.oxides_sum + ". Значение выходит за пределы интервала 98-102%.");
        }
        let ppp_prc = Math.round(item.ppp / item.oxides_sum * 10000) / 100;
        if (ppp_prc > 4) {
            result.push("П.П.П составляет " + ppp_prc + "%. Значение должно быть менньше 4%.");
        }
        if (result.length == 0)
            return null;
        else
            return { title: "Данные не валидны и не могут быть использованы.", values: result };
    }
}

export class User {
    id: string;
    name: string;
    email: string;
    silicates: Silicate[];
}
