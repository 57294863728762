import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CanAuthenticationGuardService } from './_services/can-authentication-guard.service';
import { AppComponent } from './app.component';
import { DbeditorComponent } from './dbeditor/dbeditor.component';


const routes: Routes = [
  {
    path: '', loadChildren: () => import('./app-main.module').then(m => m.AppMainModule)
    // loadChildren: './app-main.module#AppMainModule'
  },
  {
    path: 'database', loadChildren: () => import('./dbeditor/dbeditor.module').then(m => m.DbeditorModule)
    //'./dbeditor/dbeditor.module#DbeditorModule' 
    //canActivate: [CanAuthenticationGuardService], 
    //data: { roles: [] }
    // { path: '', loadChildren: './app-main.module#AppMainModule' }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  // providers: [CanAuthenticationGuardService]
})
export class AppRoutingModule { }
