<div id="container">
    <h2>Настройки</h2>
    <br />
    <table>
        <tr>
            <td>
                <img src="./assets/images/tern.png" width="150" height="119" /></td>
            <td>
                <h4>Тройная диаграмма</h4>
                Гребенников А.В. Гранитоиды А-типа: проблемы диагностики, формирования и систематики // Геология и
                геофизика. 2014. Т. 55. № 9. С. 1356–1373.
                и переводная версия: Grebennikov A.V. A-type granites and related rocks: petrogenesis and classification
                //
                Russian Geology and Geophysics. 2014. V. 55. No. 11. P. 1354–1366.
            </td>
            <td>
                <mat-slide-toggle class="example-margin" [color]="'primary'" [(ngModel)]="settings.display_ternary">
                </mat-slide-toggle>
            </td>
        </tr>
        <tr>
            <td>
                <img src="./assets/images/discr.png" width="150" height="111" /></td>
            <td>
                <h4>Бинарная диаграмма</h4>
                Whalen J.B., Currie K.L., Chappell B.W. A-type granites: geochemical characteristics,
                discrimination and petrogenesis // Contr. Miner. Petrol., 1987. V. 95. P. 407–419.
            </td>
            <td>
                <mat-slide-toggle class="example-margin" [color]="'primary'"
                    [(ngModel)]="settings.display_discriminant">

                </mat-slide-toggle>
            </td>
        </tr>
        <tr>
            <td>
                <img src="./assets/images/n_prob.png" width="150" height="111" /></td>
            <td>
                <h4>Надписи</h4>
                Отбражать на графике
            </td>
            <td>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="settings.display_text">
                    <mat-radio-button class="example-radio-button" [labelPosition]="'before'" [color]="'primary'"
                        [value]="'n_prob'"> номер пробы
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [labelPosition]="'before'" [color]="'primary'"
                        [value]="'order'"> номер строки
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [labelPosition]="'before'" [color]="'primary'"
                        [value]="'false'"> ничего
                    </mat-radio-button>
                </mat-radio-group>
            </td>
        </tr>
    </table>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="settings" cdkFocusInitial>
            Применить
        </button>
        <button mat-button [mat-dialog-close]="'null'" mat-dialog-close>Отмена</button>
    </mat-dialog-actions>
    <br />
</div>