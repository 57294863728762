import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  //url: 'http://weblogin.vsegei.ru/auth/realms/master',
  url: 'http://weblogin.vsegei.ru/auth',
  realm: 'master',
  clientId: 'web_diagram',
  credentials: {
    secret: "23ff7830-f503-4e29-a0fa-a43bba766d76"
  }  

};

export const environment = {
  production: false,
  keycloakConfig
};
