import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from './config.service';
import { KeycloakService } from 'keycloak-angular';
import { Silicate, User } from 'src/app/_model/Silicate';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,
    private keycloak: KeycloakService) {
    //this.http.get('assets/user.json').subscribe((data:User) => this.user=data);
  }

  async getUser(): Promise<User> {
    const token = await this.keycloak.getKeycloakInstance().tokenParsed;
    let user = new User();
    if (token) {
      user.id = token['sub'];
      user.name = token['given_name'];
      user.email = token['email'];
    }
    return user;
  }

  async getValues(userDetails) {
    let bearerToken = await this.keycloak.getToken();
    // let token= await this.keycloak.getKeycloakInstance().token;

    //console.log(bearerToken);


    let headers = new HttpHeaders().set('Authorization', `Bearer ${bearerToken}`);

    return this.http.get(Config.values, { headers: headers });
  }


  getUsers() {
    return this.http.get(Config.users);
  }

  getSilicates() {
    return this.http.get(Config.silicates+'GetSilicates');
  }

  createMany(silicates: Silicate[]){
    const myHeaders = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(Config.silicates+'Many', JSON.stringify(silicates), { headers: myHeaders });
  }
  createSilicate(silicate: Silicate) {
    const myHeaders = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(Config.silicates+'PostSilicate', JSON.stringify(silicate), { headers: myHeaders });
  }
  updateSilicate(id: string, silicate: Silicate) {
    const myHeaders = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(Config.silicates+'PutSilicate/' + id, JSON.stringify(silicate), { headers: myHeaders });
  }
  deleteSilicate(id: string) {
    return this.http.delete(Config.silicates+'DeleteSilicate/' + id);
  }
}
