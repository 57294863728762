import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Settings {
  display_ternary: boolean;
  display_discriminant: boolean;
  display_text: 'n_prob' | 'false' | 'order';
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  settings: Settings;

  constructor(
    public dialogRef: MatDialogRef<SettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Settings) {
    this.settings = Object.assign({}, this.data);
  }
}
