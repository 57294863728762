import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AngularSvgIconModule } from 'angular-svg-icon';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    MatButtonModule, MatIconModule, MatDividerModule,
    MatDialogModule, MatSnackBarModule, MatSlideToggleModule,
    MatRadioModule,
    MatInputModule, MatCheckboxModule
  ],
  exports:[
    AngularSvgIconModule,
    MatButtonModule, MatIconModule, MatDividerModule,
    MatDialogModule, MatSnackBarModule, MatSlideToggleModule,
    MatRadioModule,
    MatInputModule, MatCheckboxModule
  ],
})
export class MatModule { }
